import React, { useEffect } from "react";
import CustomModal from "../../../../../../components/CustomModal/CustomModal";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ColorPalette } from "../../../../../../constants/colorPalette";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useDispatch, useSelector } from "react-redux";
import { checkXeroStatus } from "../../../../../../redux/Setting/SettingSlice";
import { useParams } from "react-router-dom";
import { Info } from "@mui/icons-material";
import Loading from "../../../../../../components/LoadingIndicator/Loading";

const CheckStatusModal = ({ modalOpen, setModalOpen }) => {
  const dispatch = useDispatch();
  const { xeroStatusData, xeroStatusLoading } = useSelector(
    (state) => state.setting
  );
  const { entityId, accountingFirmId } = useParams();

  const checkStatusIcon = (val) => {
    switch (val) {
      case "success":
        return <DoneOutlinedIcon color="success" />;
      case "pending":
        return <CircularProgress size={20} thickness={3} />;
      case "error":
        return <CloseOutlinedIcon color="warning" />;
      case "notAvailable":
        return <QuestionMarkIcon />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    dispatch(checkXeroStatus({ id: entityId, accountingFirmId }));
  }, [dispatch, entityId, accountingFirmId]);
  return (
    <CustomModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      overflow={"auto"}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <Typography variant="h5">Xero Status</Typography>
        <IconButton onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {xeroStatusLoading && (
        <div style={{ minHeight: "300px" }}>
          <Loading marginTop={"10vh"} />
        </div>
      )}
      {!xeroStatusLoading && (
        <Grid container>
          <Grid item xs={12}>
            <TableContainer
              sx={{
                overflowY: "auto",
                maxHeight: "40vh",
                maxWidth: {
                  xs: 260,
                  sm: 290,
                  md: 580,
                  lg: "inherit",
                },
                marginBottom: "20px",
                margin: "20px",
              }}
            >
              <Table>
                <TableHead
                  style={{
                    position: "sticky",
                    top: "0",
                    zIndex: 1,
                    backgroundColor: ColorPalette.white,
                  }}
                >
                  <TableRow>
                    {["Xero Model", "Status"].map((header) => (
                      <TableCell key={header}>
                        <Typography variant="body1" fontWeight="bold">
                          {header}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Object.keys(xeroStatusData).map((key, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      <TableCell>
                        {checkStatusIcon(xeroStatusData[key])}
                        <Tooltip
                          sx={{
                            marginLeft: "20px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          title={`The fetch status is ${xeroStatusData[key]}`}
                        >
                          <Info />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                marginY: "10px",
              }}
            >
              <Button
                id="button-confirmModalYes"
                variant="contained"
                sx={{
                  marginRight: "10px",
                  minWidth: "100px",
                  textTransform: "none",
                }}
                onClick={() => setModalOpen(false)}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModal>
  );
};

export default CheckStatusModal;
