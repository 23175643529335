import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const getClientSpaces = createAsyncThunk(
  "client-space/get-client-spaces",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/list`;
    return handleRequest(
      endpoint,
      null,
      "getClientSpaces",
      thunkAPI,
      null,
      "GET"
    );
  }
);

export const updateClientSpace = createAsyncThunk("client-space/edit-client-space",
  async ({accountingFirmId, id, data}, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/client-space/update-clientSpace/${id}`
    return handleRequest(
      endpoint,
      data,
      "updateClientSpace",
      thunkAPI,
      null,
      "PATCH"
    )
  }
)

export const getUsersJoinedByInvitation = createAsyncThunk(
  "client-space/get-users-joined-by-invitation",
  async ({ accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/joined`;

    return handleRequest(endpoint, {}, "joinedUsers", thunkAPI, null, "GET");
  }
);

export const addClientSpace = createAsyncThunk(
  "client-space/add-client-space",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/create`;
    return handleRequest(
      endpoint,
      data.payload,
      "addClientSpace",
      thunkAPI,
      null
    );
  }
);

export const getSingleClientSpace = createAsyncThunk(
  "client-space/get-single-client-space",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/client-space/single/${id}`;

    return handleRequest(
      endpoint,
      null,
      "getSingleClientSpace",
      thunkAPI,
      null,
      "GET"
    );
  }
);

// export const getEntities = createAsyncThunk(
//   "client-space/get-entities",
//   async (data, thunkAPI) => {
//     const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/get-entities`;
//     return handleRequest(endpoint, null, "getEntities", thunkAPI, null, "GET");
//   }
// );

export const getEntity = createAsyncThunk(
  "client-space/get-entity",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/get-entity/${data.entityId}`;
    return handleRequest(endpoint, null, "getEntity", thunkAPI, null, "GET");
  }
);

export const updateOnboardingStep = createAsyncThunk(
  "client-space/updateOnboardingStep",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/onboarding-step/${data.entityId}`;
    let {step, tenant, email, clientSpaceId} = data
    return handleRequest(
      endpoint,
      { step, tenant, email, clientSpaceId },
      "updateOnboardingStep",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const addEntity = createAsyncThunk(
  "client-space/add-entity",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/create-entity`;
    return handleRequest(endpoint, data.payload, "addEntity", thunkAPI, null);
  }
);

export const updateEntity = createAsyncThunk(
  "client-space/update-entity",
  async ({accountingFirmId, id, data}, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/client-space/update-entity/${id}`;
    return handleRequest(
      endpoint,
      data,
      "addEntity",
      thunkAPI,
      null,
      "PATCH"
    );
  }
);

export const updateFinancialSetting = createAsyncThunk(
  "client-space/update-financial-setting",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/update-financial-setting/${data.payload.id}`;
    return handleRequest(
      endpoint,
      data.payload,
      "updateFinancialSetting",
      thunkAPI,
      null,
      "PATCH"
    );
  }
);

export const addMembersToClientSpace = createAsyncThunk(
  "client-space/add-members",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/add-users`;
    return handleRequest(endpoint, data.payload, "addEntity", thunkAPI, null);
  }
);

export const deleteClientSpace = createAsyncThunk(
  "client-space/delete-client-space",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/client-space/delete-clientSpace/${id}`;
    return handleRequest(
      endpoint,
      null,
      "deleteClientSpace",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);

export const deleteEntity = createAsyncThunk(
  "client-space/delete-entity",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/client-space/delete-entity/${id}`;
    return handleRequest(
      endpoint,
      null,
      "deleteEntity",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);

export const removeUsersFromClientSpace = createAsyncThunk(
  "client-space/remove-member",
  async (data, thunkAPI) => {
    const endpoint = `/accountingFirm/${data.accountingFirmId}/client-space/remove-users`;
    return handleRequest(
      endpoint,
      data.payload,
      "removeUsersFromClientSpace",
      thunkAPI,
      null
    );
  }
);

const initialState = {
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  successMessage: "",
  clientSpaces: [],
  currentClientSpace: [],
  currentClientUsers: [],
  membersJoinedByInvite: [],
  entities: [],
  currentEntity: null,
};
export const clientSpaceSlice = createSlice({
  name: "clientSpace",
  initialState,
  reducers: {
    reset: () => initialState,
    resetFlags: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientSpaces.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.clientSpaces = payload.clientSpaceList;
        return state;
      })
      .addCase(getClientSpaces.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.errorMessage = payload;
        return state;
      })
      .addCase(getClientSpaces.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getUsersJoinedByInvitation.fulfilled, (state, { payload }) => {
        state.membersJoinedByInvite = payload;
        return state;
      })
      .addCase(getUsersJoinedByInvitation.rejected, (state, { payload }) => {
        state.errorMessage = payload?.data?.message;
        return state;
      })
      .addCase(getUsersJoinedByInvitation.pending, (state) => {
        return state;
      })
      .addCase(addClientSpace.fulfilled, (state, { payload }) => {
        const clientSpaces = payload.clientSpaces;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = "";
        state.successMessage = "Client Space Added";
        state.clientSpaces = clientSpaces;
        return state;
      })
      .addCase(addClientSpace.rejected, (state, { payload }) => {
        state.isError = true;
        state.isFetching = false;
        state.isError = true;
        state.successMessage = "";
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(addClientSpace.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
        state.errorMessage = "";
        state.successMessage = "";
        return state;
      })
      .addCase(getSingleClientSpace.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentClientSpace = payload;
        state.currentClientUsers = payload.clientUsers;
        state.entities = payload.clientSpaceEntities;
        return state;
      })
      .addCase(getSingleClientSpace.rejected, (state, { payload }) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = payload;
        return state;
      })
      .addCase(getSingleClientSpace.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getEntity.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentEntity = payload.entity;
        return state;
      })
      .addCase(getEntity.rejected, (state, { payload }) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = payload;
        return state;
      })
      .addCase(getEntity.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(updateOnboardingStep.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentEntity = payload.entity;
        return state;
      })
      .addCase(updateOnboardingStep.rejected, (state, { payload }) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = payload;
        return state;
      })
      .addCase(updateOnboardingStep.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(addEntity.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "Entity Added";
        state.errorMessage = "";
        state.isError = false;
        state.entities.push(payload.newEntity);
        return state;
      })
      .addCase(addEntity.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.isError = true;
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(addEntity.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
        state.errorMessage = "";
        state.successMessage = "";
        return state;
      })
      .addCase(updateClientSpace.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "Clientspace Updated";
        state.errorMessage = "";
        state.isError = false;
                state.clientSpaces = state.clientSpaces.map((item) => {
          if (item._id === payload.updatedClient._id) {
            return { item, ...payload.updatedClient };
          } else return item;
        });
        return state;
      })
      .addCase(updateClientSpace.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.isError = true;
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(updateClientSpace.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = "";
        state.isError = false;
        return state;
      })
      .addCase(updateEntity.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "Entity Updated";
        state.errorMessage = "";
        state.isError = false;
        console.log('Update Entity Payload:', payload);
        state.entities = state.entities.map((item) => {
          if (item._id === payload.updatedEntity._id) {
            return { ...item, ...payload.updatedEntity };
          } else return item;
        });
        return state;
      })
      .addCase(updateEntity.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.isError = true;
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(updateEntity.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = "";
        state.isError = false;
        return state;
      })
      .addCase(deleteClientSpace.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "ClientSpace Deleted";
        state.errorMessage = "";
        state.isError = false;
        let index = state.clientSpaces
        ?.map((val) => val._id)
        .indexOf(payload.deletedClientSpace._id);
      state.clientSpaces.splice(index, 1);
        return state;
      })
      .addCase(deleteClientSpace.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = payload?.reason || "Failed";
        state.isError = true;
        return state;
      })
      .addCase(deleteClientSpace.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = "";
        state.isError = false;
        return state;
      })
      .addCase(deleteEntity.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "Entity Deleted";
        state.errorMessage = "";
        state.isError = false;
        let index = state.entities
        ?.map((val) => val._id)
        .indexOf(payload.deletedEntity._id);
      state.entities.splice(index, 1);
        return state;
      })
      .addCase(deleteEntity.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = payload?.reason || "Failed";
        state.isError = true;
        return state;
      })
      .addCase(deleteEntity.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = "";
        state.isError = false;
        return state;
      })
      .addCase(addMembersToClientSpace.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.successMessage = "New Members Added";
        state.errorMessage = "";
        state.isError = false;
        state.currentClientUsers = payload?.currentClientSpace?.clientUsers;
        return state;
      })
      .addCase(addMembersToClientSpace.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.successMessage = "";
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(addMembersToClientSpace.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.successMessage = "";
        state.errorMessage = "";
        return state;
      })
      .addCase(removeUsersFromClientSpace.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.successMessage = "Member Removed";
        state.errorMessage = "";
        return state;
      })
      .addCase(removeUsersFromClientSpace.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.successMessage = "";
        state.errorMessage = payload?.reason || "Failed";
        return state;
      })
      .addCase(removeUsersFromClientSpace.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.successMessage = "";
        state.errorMessage = "";
        return state;
      });
  },
});
export const { reset, resetFlags } = clientSpaceSlice.actions;

export default clientSpaceSlice.reducer;
