import React from 'react'
import CustomModal from '../../../../components/CustomModal/CustomModal'
import { Button, Grid, IconButton, InputLabel, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateClientSpace } from '../../../../redux/ClientSpace/ClientSpaceSlice';
import { useParams } from 'react-router-dom';

const EditClientSpaceModal = ({modalOpen,setModalOpen, editData}) => {
  const dispatch = useDispatch()
  const { accountingFirmId } = useParams();
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleSubmit = (values, {setSubmitting}) => {
      dispatch(updateClientSpace({
        accountingFirmId,
        id: editData._id,
        data: {clientSpaceName: values.spaceName}
        
      }))
      setSubmitting(false)
      handleModalClose()

    }
  return (
    <div>
  <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
      >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Typography variant="h5" fontWeight='bold'>
              Edit accounting Firm
            </Typography>
            <IconButton
              id='button-addGroupCloseIcon'
              size='small'
              onClick={() => {
                  handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Formik
          initialValues={{
            spaceName: editData?.name || ""
          }}
          validate = {(values) => {
            const errors = {};
            if(!values.spaceName) {
              errors.isSpaceNameEmpty = true;

            }else if (/[!@#$%^&*]/g.test(values.spaceName)) {
                errors.spaceName = "Special characters not allowed";
              }
              return errors
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting
            }) =>(
              <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
          <InputLabel
              className="font-label"
            >
            Client Space Name
            </InputLabel>
            <TextField
            fullWidth
            variant='outlined'
            name="spaceName" 
            value ={values.spaceName}
            error={!!errors.spaceName && !!touched.spaceName}
            helperText={touched.spaceName && errors.spaceName}
              defaultValue={editData?.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sx={{display: "flex", alignItems: 'center', justifyContent:'center', marginTop: "20px", gap: "10px"}}>
          <Button
              id="button-confirmModalYes"
              variant="outlined"
              sx={{
                marginRight: "10px",
                minWidth: "100px",
              }}
              onClick={() => setModalOpen(false)}
            >
              <Typography sx={{ paddingX: "10px" }}>
                 Cancel
              </Typography>
            </Button>
             <Button 
              variant='contained'
                type="submit" 
                color="primary"
                disabled={isSubmitting}
              >
                <Typography sx={{ paddingX: "20px" }}>
                Save
                </Typography>
              </Button>
      
          </Grid>
          </Grid>
              </form>
            )}

          </Formik>
   
        </CustomModal>    
      </div>
  )
}

export default EditClientSpaceModal
