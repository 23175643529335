import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ email, password }, thunkAPI) => {
    const endpoint = `/auth/login`;
    const requestData = { email, password };
    const toastIdPrefix = "login";

    return handleRequest(
      endpoint,
      requestData,
      toastIdPrefix,
      thunkAPI,
      (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("expiryTime", data.expiryTime);
        localStorage.setItem("firstName", data.first_name);
        localStorage.setItem("email", data.email);
        localStorage.setItem("accountingFirmId", data.accountingFirmId);
        localStorage.setItem(
          "reportList",
          JSON.stringify(data?.reportList || [])
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            userId: data._id,
            accountingFirmId: data.accountingFirmId,
          })
        );
      }
    );
  }
);

export const registerUser = createAsyncThunk(
  "users/register",
  async (
    { email, password, firstName, lastName, passwordConfirm },
    thunkAPI
  ) => {
    const endpoint = `/auth/register`;
    const requestData = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      password_confirm: passwordConfirm,
    };
    const toastIdPrefix = "register";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI, () => {
      localStorage.setItem("email", email);
    });
  }
);

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async ({ email }, thunkAPI) => {
    const endpoint = "/auth/forgotPassword";
    const requestData = { email };
    const toastIdPrefix = "forgotPassword";
    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI, () => {
      localStorage.setItem("email", email);
    });
  }
);

export const verifyUser = createAsyncThunk(
  "users/verifyUser",
  async ({ email, code }, thunkAPI) => {
    const endpoint = "/auth/verify";
    const requestData = { email, code };
    const toastIdPrefix = "verifyUser";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI);
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async ({ password, passwordConfirm, id, token }, thunkAPI) => {
    const endpoint = "/auth/resetPassword";
    const requestData = { password, passwordConfirm, id, token };
    const toastIdPrefix = "resetPassword";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI);
  }
);

export const inviteUser = createAsyncThunk(
  "users/invite",
  async ({ payload, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite`;
    return handleRequest(endpoint, payload, "invite", thunkAPI);
  }
);

export const addInvitedUser = createAsyncThunk(
  "users/add-invited-user",
  async (
    {
      email,
      password,
      passwordConfirm,
      first_name,
      last_name,
      accountingFirmId,
      role,
    },
    thunkAPI
  ) => {
    const endpoint = `/auth/invite-users`;
    const requestData = {
      email,
      password,
      passwordConfirm,
      first_name,
      last_name,
      accountingFirmId,
      role,
    };
    const toastIdPrefix = "addInvitedUser";

    return handleRequest(
      endpoint,
      requestData,
      toastIdPrefix,
      thunkAPI,
      (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("expiryTime", data.expiryTime);
        localStorage.setItem("email", data.email);
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
          })
        );
      }
    );
  }
);

export const getInvitations = createAsyncThunk(
  "invite-user/get-invitations",
  async ({ accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/invitations`;
    return handleRequest(endpoint, {}, "getInvitations", thunkAPI, null, "GET");
  }
);

export const resendInvitation = createAsyncThunk(
  "invite-users/resendInvitation",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/resendInvitation/${id}`;
    return handleRequest(
      endpoint,
      null,
      "resendInvitation",
      thunkAPI,
      null,
      "PATCH"
    );
  }
);
export const deleteInvitation = createAsyncThunk(
  "invite-user/delete-invitation",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/invitations/${id}`;

    return handleRequest(
      endpoint,
      null,
      "deleteInvitation",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  tenants: [],
  invitedUsers: [],
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
    resetFlags: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload?.email;
      state.firstName = payload?.first_name;
      state.lastName = payload?.last_name;
      state.tenants = payload?.tenants;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.successMessage = "New User Registered";
      state.errorMessage = "";
      return state;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [registerUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = "Forgot Password Rejected";
      return state;
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = "Reset Password Rejected";
      return state;
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [inviteUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = "";
      state.successMessage = "Invitation Sent";
      state.invitedUsers.push(...payload.newInvitation);
      return state;
    },
    [inviteUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [inviteUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [addInvitedUser.fulfilled]: (state, { payload }) => {
      state.email = payload?.data?.newUser?.email;
      state.firstName = payload?.data?.newUser?.first_name;
      state.lastName = payload?.data?.newUser?.last_name;
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [addInvitedUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [addInvitedUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [getInvitations.fulfilled]: (state, { payload }) => {
      state.invitedUsers = payload?.invitations;
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [getInvitations.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [getInvitations.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resendInvitation.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "Invitation Updated";
      state.errorMessage = "";
      state.invitedUsers = state.invitedUsers.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        } else return item;
      });
      return state;
    },
    [resendInvitation.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.reason || "Failed";
      state.successMessage = "";
      return state;
    },
    [resendInvitation.pending]: (state, { payload }) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [deleteInvitation.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "Invitation Deleted";
      state.errorMessage = "";
      let index = state.invitedUsers
        .map((val) => val._id)
        .indexOf(payload.deleteInvitation._id);
      state.invitedUsers.splice(index, 1);

      return state;
    },
    [deleteInvitation.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.reason || "Failed";
      state.successMessage = "";
      return state;
    },
    [deleteInvitation.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
  },
});
export const { reset, resetFlags } = userSlice.actions;

export default userSlice.reducer;
